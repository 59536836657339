import React, { useMemo, useState } from "react";
import useExcelReader from "../hooks/useExcelReader";
import ResourceBox from "../components/ResourceBox";
import { Box, Grid, Stack, Typography } from "@mui/material";
import MultipleSelectCheckmarks from "../components/MultiSelectCheckbox";
import useDocumentTitle from "../hooks/useDocumentTitle";

const ResourceFile = "GeneXis-Resource-Category.xlsx";

const ResourcesPage: React.FC = () => {
  const { jsonData } = useExcelReader(ResourceFile);
  useDocumentTitle("Resources - GeneXis Resource");

  //   const [ filteredData, setFilteredData ] = useState(jsonData);
  const [type, setType] = useState<string[]>([]);
  const [domain, setDomain] = useState<string[]>([]);

  const filteredData = useMemo(() => {
    if (type.length === 0 && domain.length === 0) {
      return jsonData;
    }
    return jsonData
      .filter((data) => {
        if (type.length === 0) {
          return true;
        }
        const types = data["GeneXis - Type"].split(", ");
        return type.some((t) => types.includes(t));
        // return domain.some((d) => filteredData[d] === "Primary");
      })
      .filter((data) => {
        if (domain.length === 0) {
          return true;
        }
        return domain.some((d) => data[d] === "Primary");
      });
  }, [domain, jsonData, type]);

  const domainOptions = useMemo(() => {
    // const { Description , ['GeneXis - Type']: GeneXisTypee, No, Resources, ...rest } = jsonData[0];
    const domainSet = new Set();
    jsonData.forEach((data) => {
      const {
        Description,
        "GeneXis - Type": GeneXisTypee,
        No,
        Resources,
        ...rest
      } = data;
      Object.keys(rest).forEach((key) => {
        domainSet.add(key);
      });
    });
    return Array.from(domainSet) as string[];
  }, [jsonData]);

  const typeOptions = useMemo(() => {
    const typeSet = new Set();
    jsonData.forEach((data) => {
      typeSet.add(data["GeneXis - Type"]);
    });
    const customOrder = ["Dataset", "Notebook", "Service", "Code", "Container"];
    return (Array.from(typeSet)as string[]).sort((a, b) => {
      const indexA = customOrder.indexOf(a);
      const indexB = customOrder.indexOf(b);

      // If both items are in the custom order array, sort them according to the custom order
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // If only one item is in the custom order array, that item comes first
      if (indexA !== -1) {
        return -1;
      }
      if (indexB !== -1) {
        return 1;
      }

      // If neither item is in the custom order array, they remain in their original order
      return 0;
    });
  }, [jsonData]);

  return (
    <Grid
      container
      item
      direction="column"
      gap={"20px"}
      width={863}
      margin="auto"
      id="Current-Resource-Template"
    >
      <Typography variant="h1" component={"div"}>
        Resources
      </Typography>

      <Stack direction={"row"}>
        <MultipleSelectCheckmarks
          title="Domain"
          content={domainOptions}
          onChange={setDomain}
        />
        <MultipleSelectCheckmarks
          title="Type"
          content={typeOptions}
          onChange={setType}
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "25px",
          // overflow: "auto",
        }}
      >
        {/* <ResourceBox /> */}

        {filteredData &&
          filteredData.map((data, index) => (
            <ResourceBox
              key={index}
              title={data.Resources}
              content={data.Description}
              labels={data["GeneXis - Type"].split(", ")}
            />
          ))}
      </Box>
    </Grid>
  );
};

export default ResourcesPage;

// src/dataSource/GeneXis-Resource-Category.xlsx
