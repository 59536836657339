import React, { useEffect } from "react";
import "./App.css";
import AppLayout from "./layout/AppLayout";
import HomePage from "./pages/HomePage";
import GettingStartedPage from "./pages/GettingStartedPage";
import ContactUsPage from "./pages/ContactUsPage";
import NotFound from "./pages/NotFound";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PATHS } from "./common/paths";
import useMobileDetect from "./hooks/useMobileDetect";
import TechSpecsPage from "./pages/TechSpecs";
import ResourcesPage from "./pages/ResourcesPage";

const App: React.FC = () => {
  const { isMobile } = useMobileDetect();

  useEffect(() => {
    if (isMobile) alert("This Page Is Best Viewed on Desktop");
  }, [isMobile]);

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <AppLayout>
          <Routes>
            <Route path={PATHS.Home.path} element={<HomePage />} />
            <Route path={PATHS.TechSpecs.path} element={<TechSpecsPage />} />
            <Route path={PATHS.Resources.path} element={<ResourcesPage />} />
            <Route
              path={PATHS.GettingStarted.path}
              element={<GettingStartedPage />}
            />
            <Route path={PATHS.ContactUs.path} element={<ContactUsPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppLayout>
      </BrowserRouter>
    </>
  );
};

export default App;
