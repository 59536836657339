import React from "react";
import { AppBar, Tab, Tabs, Toolbar, styled } from "@mui/material";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { PATHS, NavigationRoute } from "../common/paths";
import { ASSET_COLLECTION } from "../common/assets";
import { SiteContentContainer } from "../common/styledComponents";
const { SiteLogo } = ASSET_COLLECTION;

const pages: NavigationRoute[] = Object.values(PATHS);

const SiteLogoImage = styled("img")({
  width: 139,
  height: 40,
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.lighter,
  // borderBottom: "1px #EEE solid",
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  columnGap: 30,
  height: 80,
  paddingLeft: "0px !important",
  
});

const StyledTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.navText,
  color: "#1D1D1D",
  // padding: "8px 12px",
  padding: 0,
  minWidth: 0,
  margin: "0 12px",
  height: 75,
  '&.Mui-selected': { // This targets the active (selected) tab
    color: "#000",
  },
}));

const StyleTabs = styled(Tabs)(({ theme }) => ({
  ...theme.typography.navText,
  color: "#1D1D1D",
  marginTop: "auto",
}));

const NavGenexisLogo = (): JSX.Element => (
  // <RouterLink to="/">
    <SiteLogoImage src={SiteLogo?.filePath} alt={SiteLogo?.description} />
  // </RouterLink>
);

const SiteNavigationOptions = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuChange = (_: React.SyntheticEvent, value: string) => {
    navigate(value);
  };
  return (
    <StyleTabs value={location.pathname} onChange={onMenuChange}>
      {pages.map((page, index) => (
        <StyledTab disableRipple value={page.path} label={page.label} key={index} />
      ))}
    </StyleTabs>
  );
};

const PageNavBar: React.FC = () => (
  <StyledAppBar position="sticky" elevation={0}>
    <SiteContentContainer>
      <StyledToolbar>
        <NavGenexisLogo />
        <SiteNavigationOptions />
      </StyledToolbar>
    </SiteContentContainer>
  </StyledAppBar>
);

export default PageNavBar;
