export enum externalLinks {
  TechPassPortal = 'https://portal.techpass.gov.sg/public/home',
  TechPassOnboardingGuide = 'https://docs.developer.tech.gov.sg/docs/techpass-user-guide/sign-up-and-onboard-to-techpass',
  GeneXisPortal = 'https://genexis.gov.sg/platform',
  GeneXisSupportEmail = 'genexis-support@htx.gov.sg',
  GeneXisOnboardingGuide = 'https://form.gov.sg/65cdba162e9ab0e471238f89',
  GeneXisOnboardingForm = 'https://go.gov.sg/genexis-onboarding-form',
  SEEDOnboardingGuide = 'https://docs.developer.tech.gov.sg/docs/security-suite-for-engineering-endpoint-devices/', //'https://docs.developer.tech.gov.sg/docs/security-suite-for-engineering-endpoint-devices/prerequisites-for-onboarding',
  SEEDPortal = 'https://docs.developer.tech.gov.sg/docs/security-suite-for-engineering-endpoint-devices/',
  GeneXisInterestForm = 'https://go.gov.sg/genexis-launch-interest',
  ReportVulnerability = "https://www.tech.gov.sg/report_vulnerability",
  PrivacyStatement = "https://www.htx.gov.sg/privacy-statement",
}
