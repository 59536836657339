import React, { ReactNode } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { externalLinks } from "../common/externalLinks";
import { ASSET_COLLECTION, AssetDetails } from "../common/assets";

export interface Step {
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export type StepList = Step[];

const openNewTabProps = {
  target: "_blank",
  rel: "noopener noreferrer",
};

const OnBoardingLink: React.FC<{
  linkPath:
    | externalLinks
    | `mailto:${externalLinks}`
    | AssetDetails["filePath"];
  children: ReactNode;
}> = ({ linkPath, children }) => (
  <Link href={linkPath}  {...openNewTabProps}>
    <Typography
      variant="body"
      // sx={(theme) => ({ color: theme.palette.primary.main })}
    >
      {children}
    </Typography>
  </Link>
);

export const OnBoardingSteps: StepList = [
  {
    title: (
      <Typography variant="cardHeader" sx={{ fontWeight: 700 }}>
        Create a TechPass Account
      </Typography>
    ),
    description: (
      <Typography variant="body">
        Access to GeneXis is provided via GovTech{" "}
        <OnBoardingLink linkPath={externalLinks.TechPassPortal}>
          TechPass Portal
        </OnBoardingLink>{" "}
        (only accessible via GSIB laptop). The TechPass onboarding guide can be
        found{" "}
        <OnBoardingLink linkPath={externalLinks.TechPassOnboardingGuide}>
          here
        </OnBoardingLink>
      </Typography>
    ),
  },
  {
    title: (
      <Typography variant="cardHeader" sx={{ fontWeight: 700 }}>
        Complete Onboarding Form
      </Typography>
    ),
    description: (
      <Stack direction={'column'}>
        <Typography variant="body">
          Register your interest via this{" "}
          <OnBoardingLink linkPath={externalLinks.GeneXisOnboardingGuide}>
            FormSG
          </OnBoardingLink>{" "}
          or scan the QR code below.
        </Typography>
        <img
          src={ASSET_COLLECTION.OnboardingFormQR.filePath}
          alt={ASSET_COLLECTION.OnboardingFormQR.description}
          width={180}
          height={180}
        />
      </Stack>
    ),
  },
  {
    title: (
      <Typography variant="cardHeader" sx={{ fontWeight: 700 }}>
        {" "}
        Log in to GeneXis
      </Typography>
    ),
    description: (
      <Typography variant="body">
        You should receive an invitation email within 3 working days to login to{" "}
        <OnBoardingLink linkPath={externalLinks.GeneXisPortal}>
          GeneXis
        </OnBoardingLink>
      </Typography>
    ),
  },
  {
    title: (
      <Typography variant="cardHeader" sx={{ fontWeight: 700 }}>
        Onboard to SEED (optional)
      </Typography>
    ),
    description: (
      <Typography variant="body">
        To access GCC services via your internet laptop, you need to onboard
        your device to SEED. Access the{" "}
        <OnBoardingLink linkPath={externalLinks.SEEDPortal}>
          SEED
        </OnBoardingLink>{" "}
        onboarding guide{" "}
        <OnBoardingLink linkPath={externalLinks.SEEDOnboardingGuide}>
          here
        </OnBoardingLink>
      </Typography>
    ),
  },
];
