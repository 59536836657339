import { ASSET_COLLECTION } from "../common/assets";
const { LockIcon, InfoIcon, CommunityIcon } = ASSET_COLLECTION;

export const BENEFITS_DATA = [
  {
    imageSrc: LockIcon.filePath,
    title: "Secured development platform",
    text: "Genexis is hosted on GCC 2.0 (AWS), enabling developers to build solutions within a secured, IM-compliant environment for in-house development using datasets classified up to Restricted (Sensitive-normal)",
  },
  {
    imageSrc: CommunityIcon.filePath,
    title: "Accelerated innovation pipelines",
    text: "Genexis supports end-to-end solutions that can be demonstrated to users from both internet and intranet devices. This enables product growth by pairing user feedback with agile product delivery.",
  },
  {
    imageSrc: InfoIcon.filePath,
    title: "Central platform for collaboration",
    text: "Genexis platform has a community repository of datasets, notebooks, and libraries. Developers can pull these resources into their projects and publish their own resources back to the platform for sharing.",
  },
];
