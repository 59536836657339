import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ASSET_COLLECTION } from "../common/assets";
import { externalLinks } from "../common/externalLinks";

const { ResourceImage } = ASSET_COLLECTION;

const overviewContent = {
  overview: "Introducing GeneXis",
  title: "A Central Development & Testing Platform for MHA",
  content: `Developed by S&S CoE, GeneXis is a secured, IM-compliant, and cloud-based platform hosted on GCC 2.0 (AWS) that provides AI/ML, Software, and Sensors & IoT development pipelines to build end-to-end solutions for Home Team use cases.`,
};

type OverviewContent = {
  overview: string;
  title: string;
  content: string;
};

type OverviewContentProps = {
  desciptions: OverviewContent;
};

const handleClick = () =>
  window.open(externalLinks.GeneXisInterestForm, "_blank"); // Opens in a new tab

const GenexisOverviewContent: React.FC<OverviewContentProps> = ({
  desciptions,
}) => (
  <Grid alignContent="start" container item gap={"12px"} width={"495px"} my={'auto'} >
    <Typography variant="body">{desciptions.overview}</Typography>
    <Typography variant="h1">{desciptions.title}</Typography>
    <Typography variant="bodylarge" sx={{ mt: "6px", mb: "18px" }}>
      {desciptions.content}
    </Typography>
    <Button variant="contained" onClick={handleClick} >
      Register Your Interest
    </Button>
  </Grid>
);

const GenexisOverview: React.FC = () => {
  return (
    <Grid item container justifyContent={'space-between'}>
      <GenexisOverviewContent desciptions={overviewContent} />
      <img
        src={ResourceImage?.filePath}
        alt={ResourceImage?.description}
      />
    </Grid>
  );
};

export default GenexisOverview;


