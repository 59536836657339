import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bodyBold: React.CSSProperties;
    body: React.CSSProperties;
    bodylarge: React.CSSProperties;
    bodySmall: React.CSSProperties;
    navText: React.CSSProperties;
    cardHeader: React.CSSProperties;
    footerText: React.CSSProperties;
    tagText: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    bodyBold?: React.CSSProperties;
    body?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    bodylarge?: React.CSSProperties;
    navText: React.CSSProperties;
    cardHeader: React.CSSProperties;
    footerText: React.CSSProperties;
    tagText: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    body: true;
    bodySmall: true;
    bodylarge: true;
    navText: true;
    cardHeader: true;
    footerText: true;
    tagText: true;
  }
}

export const typography: TypographyOptions = {
  fontFamily: [
    'Faktum',
    'Noto Sans',
    'Helvetica' /* A commonly available sans-serif font */,
    'Arial' /* Another commonly available sans-serif font */,
    'sans-serif' /* Generic sans-serif fallback */,
  ].join(','),
  h1: {
    fontSize: '2.25rem',
    fontWeight: 700,
  },

  h2: {
    fontSize: '2.1rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '1.375rem',
    fontWeight: 700,
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  cardHeader: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  bodylarge: {
    fontSize: '1.125rem', // 18px
    fontWeight: 400,
    letterSpacing: "0.48px",
    lineHeight: "24px",
  },
  body: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: "1.5rem",
  },
  bodySmall: {
    fontSize: '0.875rem',
    fontWeight: 400,
    // letterSpacing: '0.25px'
  },
  bodyBold: {
    fontSize: '1rem',
    fontWeight: 700,
    letterSpacing: '0.15px',
  },
  navText: {
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  footerText: {
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: '0.15px',
    lineHeight: "24px",
  },
  tagText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    textTransform: 'capitalize',
    fontFamily: 'Noto Sans',
    lineHeight: "19px",
  },
};

// export const responsiveTypography = {
//   h1: {
//     '@media (max-width:800px)': {
//       fontSize: '2.0rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '1.9rem',
//     },
//   },
//   h2: {
//     '@media (max-width:800px)': {
//       fontSize: '2.0rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '1.8rem',
//     },
//   },
//   h3: {
//     '@media (max-width:800px)': {
//       fontSize: '1.3rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '1.3rem',
//     },
//   },
//   h4: {
//     '@media (max-width:800px)': {
//       fontSize: '1.4rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '1.3rem',
//     },
//   },
//   h5: {
//     '@media (max-width:800px)': {
//       fontSize: '1.2rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '1.15rem',
//     },
//   },
//   h6: {
//     '@media (max-width:800px)': {
//       fontSize: '1.2rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '1.15rem',
//     },
//   },
//   body: {
//     '@media (max-width:800px)': {
//       fontSize: '0.95rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '0.9rem',
//     },
//   },
//   bodySmall: {
//     '@media (max-width:800px)': {
//       fontSize: '0.75rem',
//     },
//     '@media (max-width:600px)': {
//       fontSize: '0.7rem',
//     },
//   },
// };
