import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid } from "@mui/material";
import {
  formattedFAQList,
  QAEntry,
  FormattedFAQs,
  SubTopics,
} from "../dataSource/FAQsList";

import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    borderRadius: 20,
    padding: "0.25rem 1rem",
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
    },

    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.dark}`,
      },
    },
    "&:not(:last-child)": {
      borderRadius: 20,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "&:not(:first-child)": {
      borderRadius: 20,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const StyledFAQWrapper = styled(Grid)(({ theme }) => ({
  width: 862,
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "50px",
  alignItems: "flex-start",
  
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "0.75rem",
  fontWeight: 500,
}));

const StyleToggleFilter = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

// individual FAQ item
const FAQItem: React.FC<QAEntry & { lastRow: boolean }> = ({
  question,
  answer,
  lastRow,
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded((prev) => !prev);

  useEffect(() => {
    setExpanded(false);
  }, [question]);

  return (
    <Accordion
      expanded={expanded}
      onChange={toggleExpand}
      sx={(theme) => ({
        py: 4,
        border: "none",
        boxShadow: "none",
        borderBottom: lastRow ? "none" : "1px solid #DDDDDD",
        backgroundColor: theme.palette.background.default,
      })}
    >
      <AccordionSummary
        sx={{ p: 0 }}
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
      >
        <Typography variant="bodyBold" sx={{ p: 0 }}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
        }}
      >
        <Typography variant="body">{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

// FAQ group items per subtopic
const FAQGroupItems: React.FC<QAEntry & { lastRow: boolean }> = ({
  question,
  answer,
  lastRow,
}) => (
  <Grid item xs={12} lg={12}>
    <FAQItem question={question} answer={answer} lastRow={lastRow} />
  </Grid>
);

// FAQ group subtopic
const FAQGroup: React.FC<FormattedFAQs & {lastFaqSubGroup: boolean}> = ({ subTopic, qaList, lastFaqSubGroup }) => (
  <Grid container item direction="column">
    <Grid container item>
      <Typography variant="h3">{subTopic}</Typography>
    </Grid>
    <Grid item container>
      {qaList.map((qaItem: QAEntry, innerIndex: number) => (
        <FAQGroupItems
          // question={`${innerIndex + 1}. ${qaItem.question}`}
          question={`${qaItem.question}`}
          answer={qaItem.answer}
          key={innerIndex}
          lastRow={(innerIndex >= qaList.length - 1) && lastFaqSubGroup}
        />
      ))}
    </Grid>
  </Grid>
);

const FAQToggleFilter: React.FC<{ onFilterChange: any }> = ({
  onFilterChange,
}) => {
  const [category, setCategory] = React.useState<SubTopics>(SubTopics.all);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newTopic: SubTopics
  ) => {
    if (!newTopic || category === newTopic) return;
    setCategory(newTopic);
    onFilterChange(newTopic);
  };

  return (
    <StyleToggleFilter container item>
      <StyledToggleButtonGroup
        size="small"
        value={category}
        exclusive
        onChange={handleChange}
        aria-label="topic filter"
      >
        {Object.entries(SubTopics).map(([key, value]) => (
          <StyledToggleButton key={key} value={value} aria-label={key}>
            {value}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </StyleToggleFilter>
  );
};

// FAQ main comp
// entry point
const FAQComp: React.FC = () => {
  const [faqList, setFaqList] = React.useState(formattedFAQList);

  const updateFaqList = (subTopic: SubTopics) => {
    setFaqList([]);
    setTimeout(() => {
      subTopic === SubTopics.all
        ? setFaqList(formattedFAQList)
        : setFaqList(
            formattedFAQList.filter((faq) => faq.subTopic === subTopic)
          );
    }, 1);
  };

  return (
    <StyledFAQWrapper container item lg={9}>
      <Grid container item gap={"25px"}>
        <Typography variant="h1">FAQ</Typography>
        <FAQToggleFilter onFilterChange={updateFaqList} />
      </Grid>
      <Grid container item direction="column" gap={10}>
        {faqList.map(
          (faq: FormattedFAQs, index: number): React.ReactNode => (
            <FAQGroup {...faq} key={index} lastFaqSubGroup={index >= faqList.length - 1} />
          )
        )}
      </Grid>
    </StyledFAQWrapper>
  );
};

export default FAQComp;
