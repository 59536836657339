import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
// import GenexisPagehNavBanner from '../components/GenexisBanner';
import SiteFooter from '../components/SiteFooter';
import PageNavBar from '../components/NavigationBar';
import { SiteContentContainer, SiteLayoutContainer } from '../common/styledComponents';

interface LayoutContainerProps {
  children: ReactNode;
}

const AppLayout: React.FC<LayoutContainerProps> = ({ children }) => (
  <SiteLayoutContainer>
    <PageNavBar />
    {/* <GenexisPagehNavBanner /> */}
    <MainContentContainer>{children}</MainContentContainer>
    <SiteFooter />
  </SiteLayoutContainer>
);

export default AppLayout;

const MainContentContainer: React.FC<LayoutContainerProps> = ({ children }) => (
  /* <Grid container sx={{ paddingTop: "100px", pb: '300px', overflow: 'auto' }}> */
  <SiteContentContainer sx={{ flex: 1, pt: '60px', pb: '100px' }}>
    <Grid container direction="column" gap={15}>
      {children}
    </Grid>
  </SiteContentContainer>
  // </Grid>
);
