import React from 'react';
import { Box, Button, Typography, Grid, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../common/paths';

const LargerTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 900,
  fontSize: '15rem',
}));

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate(PATHS.Home.path);
  };

  return (
    <Box>
      <Grid container direction="column" gap={4} alignItems="start">
        <LargerTypo>404</LargerTypo>
        <Typography variant="h1">OOPS! This Page Could Not Be Found</Typography>
        <Typography variant="h4">
          Sorry but the page you are looking for does not exist, have been
          removed, name changed or is temporarily unavailable
        </Typography>
        <Button
          variant="contained"
          color="error"
          sx={{ margin: '100px 0' }}
          onClick={handleGoToHome}
        >
          Go To Homepage{' '}
        </Button>
      </Grid>
    </Box>
  );
};

export default NotFound;
