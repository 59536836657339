import { useState, useEffect } from 'react';

// Custom hook for detecting mobile device
const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    // Check based on user agent
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileUserAgent = /mobile|android|touch|webos|iphone|ipad|ipod/i.test(userAgent);

    // Check based on screen width
    // const isMobileScreenWidth = window.matchMedia("(max-width: 768px)").matches;

    // Combine both checks for a more comprehensive result
    return isMobileUserAgent // || isMobileScreenWidth;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkMobile());
    };

    handleResize(); // Perform an initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {isMobile};
};

export default useMobileDetect;
