// #TODO refactor
import React from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import TechSpec from "../dataSource/TechSpec.json";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  ToggleButton,
  Grid,
} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { styled } from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    borderRadius: 20,
    padding: "0.25rem 1rem",
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
    },

    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.dark}`,
      },
    },
    "&:not(:last-child)": {
      borderRadius: 20,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "&:not(:first-child)": {
      borderRadius: 20,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

const TechSpecsPage: React.FC = () => {
  useDocumentTitle("Tech Specs - GeneXis Resource");
  return (
    <Grid
      container
      item
      direction="column"
      gap={"20px"}
      width={863}
      margin="auto"
    >
      <Typography variant="h1" component={"div"}>
        Tech Specs
      </Typography>
      <CustomTable />
    </Grid>
  );
};

export default TechSpecsPage;

const StyledTableCell = styled(TableCell)`
  && {
    border: 0;
  }
  vertical-align: top;
  padding: 35px 25px;
  width: 250px;
`;

const TechSpecsItem: React.FC<{ item: any; lastEntry: boolean }> = ({
  item,
  lastEntry,
}) => {
  const { Category, ...content } = item;

  const TitleHtml = (
    <StyledTableCell component="th" scope="row">
      <Typography variant="h3" gutterBottom component="div">
        {Category}
      </Typography>
    </StyledTableCell>
  );

  const renderData = [];

  const contentHeaders = Object.keys(content);
  const contentValues = Object.values(content);

  for (let i = 0; i < contentHeaders.length; i += 2) {
    let innerTableHtml = [];
    let lastRowEntry = i + 2 >= contentHeaders.length;
    if (i === 0) {
      innerTableHtml.push(TitleHtml);
    } else {
      innerTableHtml.push(
        <StyledTableCell
          component="th"
          scope="row"
          sx={{ border: 0 }}
        ></StyledTableCell>
      );
    }

    if (i < contentHeaders.length) {
      innerTableHtml.push(
        <StyledTableCellGrp
          header={contentHeaders[i]}
          content={contentValues[i] as string}
        />
      );
    }

    if (i + 1 < contentHeaders.length) {
      // debugger
      innerTableHtml.push(
        <StyledTableCellGrp
          header={contentHeaders[i + 1]}
          content={contentValues[i + 1] as string}
        />
      );
    }

    renderData.push(
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          borderBottom:
            lastEntry || !lastRowEntry ? "none" : "1px solid #e0e0e0",
        }}
      >
        {innerTableHtml.map((item) => item)}
      </TableRow>
    );
  }

  return <>{renderData.map((item) => item)}</>;
};

const CustomTable = () => {
  const [items, setItems] = React.useState(Object.values(TechSpec));

  const handleFilterChange = (newTopic: string) => {
    if (newTopic === "All") {
      setItems(Object.values(TechSpec));
    } else {
      setItems(
        Object.values(TechSpec).filter((item) => item.Category === newTopic)
      );
    }
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <FAQToggleFilter onFilterChange={handleFilterChange} />
      <Table
        sx={(theme) => ({
          width: "100%",
          background: theme.palette.background.default,
          "& .MuiTableCell-root": {
            paddingLeft: "0px",
            paddingRight: "35px",
            paddingTop: "40px"
          }
        })}
        aria-label="tech spec table"
      >
        <TableBody>
          {items.map((item, index) => (
            <TechSpecsItem item={item} lastEntry={index >= items.length - 1} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCellGrp: React.FC<{ header: string; content: string }> = ({
  header,
  content,
}) => {
  return (
    <StyledTableCell component="th" scope="row" sx={{ border: 0 }}>
      <Typography variant="bodyBold" gutterBottom component="div">
        {header}
      </Typography>
      <Typography variant="body" gutterBottom component="div">
        {content}
      </Typography>
    </StyledTableCell>
  );
};

const FAQToggleFilter: React.FC<{ onFilterChange: any }> = ({
  onFilterChange,
}) => {
  // const allCategories = TechSpec.map((item) => item.Category);
  const [category, setCategory] = React.useState<string>("All");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newTopic: string
  ) => {
    if (!newTopic || category === newTopic) return;
    setCategory(newTopic);
    onFilterChange(newTopic);
  };

  return (
    <StyleToggleFilter container item>
      <StyledToggleButtonGroup
        size="small"
        value={category}
        exclusive
        onChange={handleChange}
        aria-label="topic filter"
      >
        <StyledToggleButton value={"All"} aria-label={"All"}>
          All
        </StyledToggleButton>
        {Object.entries(TechSpec).map(([key, value]) => (
          <StyledToggleButton value={value.Category} aria-label={key}>
            {value.Category}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </StyleToggleFilter>
  );
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "0.75rem",
  fontWeight: 500,
}));

const StyleToggleFilter = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
