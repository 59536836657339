import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

const StyledResourceCard = styled(Card)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: 271,
  height: 231,
  padding: 24,
  // border: "1px solid #E5E5E5",
  borderRadius: 8,
  boxShadow: 'none',
  border: '1px solid #DDDDDD',
  // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.05)",
  
  // "&:hover": {
  //   background: theme.palette.secondary.light,
  // },
}));

const StyledResourceCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  gap: 12,
  padding: 0,
  paddingBottom: "0 !important",
}));

const StyledResourceLabelTag = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: "#4637B7",
  padding: "4px 8px",
  borderRadius: 4,
  textTransform: "capitalize",
  width: "fit-content",
}));

const ResourceBox: React.FC<{
  title: string;
  content: string;
  labels: string[];
}> = ({ title, content, labels }) => {
  return (
    <StyledResourceCard>
      <StyledResourceCardContent>
        <Typography variant="cardHeader" component={"div"}>
          {title}
        </Typography>
        <Typography variant="bodySmall" component={"div"} overflow={"auto"}>
          {content}
        </Typography>
        <Stack direction={"row"} gap={1} marginTop={"auto"}>
          {labels.map((label, index) => (
            <StyledResourceLabelTag key={index}>
              <Typography variant="tagText" component={"div"}>
                {label}
              </Typography>
            </StyledResourceLabelTag>
          ))}
        </Stack>
      </StyledResourceCardContent>
    </StyledResourceCard>
  );
};

export default ResourceBox;
