import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

type UseExcelReaderReturn = {
  jsonData: any[];  // Update with specific type if known
  error: string | null;
};

function useExcelReader(filePath: string): UseExcelReaderReturn {
  const [jsonData, setJsonData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const readExcelFile = async () => {
      try {
        const response = await fetch(filePath);
        // debugger
        if (!response.ok) {
          throw new Error('Failed to fetch file');
        }
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'buffer' });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setJsonData(json);
        setError(null);
      } catch (e: any) {
        setError(e.message || 'Failed to read the file');
        setJsonData([]);
      }
    };

    readExcelFile();
  }, [filePath]);

  return { jsonData, error };
}

export default useExcelReader;
