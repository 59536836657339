export enum localPaths {
  Home = "/",
  TechSpecs = "/tech-specs",
  Resources = "/resources",
  GettingStarted = "/getting-started",
  FAQs = "/FAQ",
}

export interface NavigationRoute {
  label: string;
  path: string;
}

export interface AppRoutes {
  Home: NavigationRoute;
  TechSpecs: NavigationRoute;
  Resources: NavigationRoute;
  GettingStarted: NavigationRoute;
  ContactUs: NavigationRoute;
}

const Home: NavigationRoute = {
  label: "Home",
  path: localPaths.Home,
};

const TechSpecs: NavigationRoute = {
  label: "Tech Specs",
  path: localPaths.TechSpecs,
};

const Resources: NavigationRoute = {
  label: "Resources",
  path: localPaths.Resources,
};

const GettingStarted: NavigationRoute = {
  label: "Getting Started",
  path: localPaths.GettingStarted,
};

const ContactUs: NavigationRoute = {
  label: "FAQ",
  path: localPaths.FAQs,
};

export const PATHS: AppRoutes = {
  Home,
  TechSpecs,
  Resources,
  GettingStarted,
  ContactUs,
};
