import React from 'react';
import GenexisOverview from '../components/GenexisOverview';
import GenexisBenefits from '../components/GenexisBenefits';
// import CalendarEvents from '../components/CalendarEvents';
// import GenexisResourcesTemplates from '../components/ResourcesTemplates';
import useDocumentTitle from '../hooks/useDocumentTitle';

const HomePage: React.FC = () => {
  useDocumentTitle('Home - Genexis Resource');
  return (
    <>
      <GenexisOverview />
      {/* <GenexisBenefitContainer> */}
        {/* <Grid item xs={12} lg={8}> */}
          <GenexisBenefits />
        {/* </Grid> */}
        {/* <Grid item xs={12} lg={4}>
          <CalendarEvents />
        </Grid> */}
      {/* </GenexisBenefitContainer> */}
      {/* <GenexisResourcesTemplates /> */}
    </>
  );
};

export default HomePage;

/*


interface GenexisBenefitContainerProps {
  children: ReactNode;
}

const GenexisBenefitContainer: React.FC<GenexisBenefitContainerProps> = ({
  children,
}) => (
  <Grid item container justifyContent="center" alignItems="center" spacing={10}>
    {children}
  </Grid>
);
*/