import { Theme, createTheme } from '@mui/material';
import { palette } from './palette';
import { typography, /* responsiveTypography */ } from './typography';

// The theme object's type is a combination of MUI's Theme type and our custom properties
// type CustomTheme = Theme & { palette: CustomPalette };

const baseTheme: Theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: '0.75rem 1.5rem',
          fontFamily: 'Inter',
          fontWeight: 600,
          // letterSpacing: 0.55,
          textTransform: 'capitalize',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          boxShadow: "none",
        },
      },
    },
  },
});

export const theme: Theme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: '0.9rem', // Set the desired font size for buttons
          // fontWeight: 700, // Set the desired font weight for buttons
        },
        containedPrimary: {
          backgroundColor: baseTheme.palette.primary.main,
          color: baseTheme.palette.getContrastText(
            baseTheme.palette.primary.main
          ),
          // '&:hover': {
          //   backgroundColor: baseTheme.palette.primary.light,
          //   color: baseTheme.palette.getContrastText(
          //     baseTheme.palette.primary.dark
          //   ),
          // },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          color: baseTheme.palette.primary.main,
          // '&:hover': {
          //   color: baseTheme.palette.primary.main,
          //   cursor: 'pointer',
          // },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        // ...responsiveTypography,
      },
    },
  },
});
