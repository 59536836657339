import React from "react";
import { Card, CardMedia, CardContent, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AssetDetails } from "../common/assets";
import { BENEFITS_DATA } from "../dataSource/GenexisBenefits";

const StyledBenefitCard = styled(Card)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: 271,
  height: 318,
  padding: 24,
  border: "1px solid #E5E5E5",
  borderRadius: 8,
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.05)",
  // "&:hover": {
  //   background: theme.palette.secondary.light,
  // },
}));

const StyledBenefitCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  gap: 20,
  padding: 0,
}));

const StyledCardTextContentContainer = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
}));

interface BenefitProps {
  imageSrc: AssetDetails["filePath"];
  title: string;
  text: string;
}

const BenefitCard: React.FC<BenefitProps> = ({ imageSrc, title, text }) => (
  <StyledBenefitCard elevation={0}>
    <StyledBenefitCardContent>
      <CardMedia
        component="img"
        sx={{ height: 50, width: 50 }}
        image={imageSrc}
        alt={text}
      />
      <StyledCardTextContentContainer container item>
        <Typography component={"div"} variant="cardHeader">
          {title}
        </Typography>
        <Typography variant="bodySmall">{text}</Typography>
      </StyledCardTextContentContainer>
    </StyledBenefitCardContent>
  </StyledBenefitCard>
);

const GenexisBenefits: React.FC = () => (
  <Grid container gap={"50px"}>
    <Grid container item flexDirection={"column"} alignItems={"center"}>
      <Typography variant="h1">Develop, Secure, Share.</Typography>
      <Typography variant="h1">With GeneXis.</Typography>
    </Grid>

    <Grid item container justifyContent={"center"} gap={3}>
      {BENEFITS_DATA.map((benefit, index) => (
        <Grid item key={index}>
          <BenefitCard {...benefit} />
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default GenexisBenefits;
