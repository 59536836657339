export enum AssetKinds {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Document = 'document',
}

export interface AssetDetails {
  type: AssetKinds;
  description: string;
  filePath: string;
}

export type AssetGroup = Record<string, AssetDetails>;

const BASE_DIRECTORY = '/';

export const ASSET_COLLECTION: AssetGroup = {
  SiteLogo: {
    type: AssetKinds.Image,
    description: 'Genexis Logo',
    filePath: `${BASE_DIRECTORY}site-logo.svg`,
  },
  // LogoDark: {
  //   type: AssetKinds.Image,
  //   description: "Genexis Logo Dark",
  //   filePath: `${BASE_DIRECTORY}Genexis logo dark background.png`,
  // },
  Banner: {
    type: AssetKinds.Image,
    description: 'Genexis Banner',
    filePath: `${BASE_DIRECTORY}banner-home.svg`,
  },
  BannerHome: {
    type: AssetKinds.Image,
    description: 'Genexis Home Banner',
    // filePath: `${BASE_DIRECTORY}banner-home.svg`,
    filePath: `${BASE_DIRECTORY}banner-common-home.svg`
  },
  BannerGettingStarted: {
    type: AssetKinds.Image,
    description: 'Genexis Getting Started',
    // filePath: `${BASE_DIRECTORY}banner-getting-started.svg`,
    filePath: `${BASE_DIRECTORY}banner-common-home.svg`
  },
  BannerContact: {
    type: AssetKinds.Image,
    description: 'Genexis Contact Us',
    // filePath: `${BASE_DIRECTORY}banner-contact-us.svg`,
    filePath: `${BASE_DIRECTORY}banner-common-home.svg`
  },
  BannerHomeMain: {
    type: AssetKinds.Image,
    description: 'Genexis Home Banner Main',
    filePath: `${BASE_DIRECTORY}banner-home-main.svg`,
  },

  // BannerDark: {
  //   type: AssetKinds.Image,
  //   description: "Genexis Banner Dark",
  //   filePath: `${BASE_DIRECTORY}Genexis banner dark.png`,
  // },
  LockIcon: {
    type: AssetKinds.Image,
    description: 'Padlock Icon',
    filePath: `${BASE_DIRECTORY}security.svg`,
  },
  InfoIcon: {
    type: AssetKinds.Image,
    description: 'Knowledge Icon',
    filePath: `${BASE_DIRECTORY}knowledge.svg`,
  },
  CommunityIcon: {
    type: AssetKinds.Image,
    description: 'Accelerate Icon',
    filePath: `${BASE_DIRECTORY}accelerate.svg`,
  },
  OnboardingFlowchart: {
    type: AssetKinds.Image,
    description: 'OnBoarding Process Flow Chart',
    filePath: `${BASE_DIRECTORY}sampleFlowChart.png`,
  },
  ResourceImage: {
    type: AssetKinds.Image,
    description: 'Resource available in genesis',
    filePath: `${BASE_DIRECTORY}overview.svg`,
  },
  GeneXisReportVulnerability: {
    type: AssetKinds.Image,
    description: 'Expand More Icon',
    filePath: `${BASE_DIRECTORY}expand-more.svg`,
  },
  OnboardingForm: {
    type: AssetKinds.Document,
    description: 'Onboarding Form',
    filePath: `${BASE_DIRECTORY}GeneXis New User Onboarding Details v2.0.xlsx`,
  },
  OnboardingFormQR: {
    type: AssetKinds.Image,
    description: 'Onboarding Form QR Code',
    filePath: `${BASE_DIRECTORY}onBoardingQR.svg`,
  },
};
