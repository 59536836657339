import { PaletteOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface TypeBackground {
    darker?: string;
    lighter?: string;
  }
  interface TypeText {
    hint?: string;
  }
}

export const palette: PaletteOptions = {
  secondary: {
    light: '#f7f7f7', // Slightly off-white
    main: '#ffffff', // Pure white
    dark: '#e0e0e0', // Light gray
    contrastText: '#000000', // Black text for legibility
  },
  primary: {
    light: '#EEECF9', // Lighter shade of #755fe3
    main: '#7364E1',
    dark: '#381E72', // Darker shade of #755fe3
    contrastText: '#ffffff', // White text will likely provide good contrast here
  },
  background: {
    default: '#FDFDFD', //background: #F7F2FA;
    darker: '#141218',
    lighter: '#FFFFFF',
  },
  text: {
    primary: '#000000', // main text color
    secondary: '#FFFFFF', // secondary text color
    disabled: 'rgba(255, 255, 255, 0.38)', // text color for disabled elements
    hint: 'rgba(255, 255, 255, 0.38)', // hint text color, used for placeholders
  },
};
