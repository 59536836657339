import React from 'react';
// import InterestFormComp from '../components/InterestFormComp';
import FAQComp from '../components/FAQComp';
// import { Grid } from '@mui/material';
// import { ASSET_COLLECTION } from '../common/assets';
import useDocumentTitle from '../hooks/useDocumentTitle';
// const { ResourceImage } = ASSET_COLLECTION;

const ContactUsPage: React.FC = () => {
  useDocumentTitle('FAQ - Genexis Resource');
  return (
    <>
      {/* <Grid item container spacing={8} alignItems={'center'}>
        <Grid item xs={12} lg={6}>
          <InterestFormComp />
        </Grid>
        <Grid item xs={6} lg={6}>
          <img
            src={ResourceImage?.filePath}
            alt={ResourceImage?.description}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid> */}
      <FAQComp />
    </>
  );
};

export default ContactUsPage;
