import React from 'react';
import OnBoardingGuide from '../components/OnBoardingGuide';
import useDocumentTitle from '../hooks/useDocumentTitle';

const GettingStartedPage: React.FC = () => {
  useDocumentTitle('Getting Started - Genexis Resource');
  return (
    <>
      <OnBoardingGuide />
    </>
  );
};

export default GettingStartedPage;
