import React from "react";
import Link from "@mui/material/Link";
import { PATHS, NavigationRoute } from "../common/paths";
import { externalLinks } from "../common/externalLinks";
import { useNavigate } from "react-router-dom";

const FAQLink: React.FC<{
  linkPath: externalLinks | NavigationRoute["path"];
  linkText: string;
  customHandler?: () => void;
}> = ({ linkPath, linkText, customHandler = () => {} }) => {
  const navigate = useNavigate();
  return (
    <Link
      variant="bodyBold"
      sx={{ color: "darkblue", cursor: "pointer" }}
      onClick={() => {
        if (
          typeof linkPath === "string" &&
          (linkPath.startsWith("http://") || linkPath.startsWith("https://"))
        ) {
          // Open external link
          window.open(linkPath, "_blank");
        } else {
          // Use React Router for internal navigation
          navigate(linkPath);
        }
        customHandler();
      }}
    >
      {linkText}
    </Link>
  );
};

// Enum for FAQ subtopics
export enum SubTopics {
  all = "All",
  developing = "Project",
  collaboration = "Data Sharing",
  demonstrate = "Demonstration",
  // sharing = "Demonstration",
  costing = "Costing",
}

// Interface for a single question and answer pair
export interface QuestionAnswer {
  subTopic: SubTopics;
  question: string;
  answer: JSX.Element | string;
}

// Type for an array of FAQs
export type FAQsList = QuestionAnswer[];

// List of FAQs
export const faqsQuestions = [
  {
    subTopic: SubTopics.developing,
    question: `How can I create a project on GeneXis?`,
    answer: `You can submit a request to create a new Project via GeneXis Platform. After the Project is created, Project Owners can add other users to the project space.`,
  },
  // {
  //   subTopic: SubTopics.developing,
  //   question: `Is there a fixed format I must follow to start my project on the GeneXis platform?`,
  //   answer: `Yes. GeneXis users can submit a "request new project" form on GeneXis Platform page to create a Project where they will be assigned as Project admin by default.`,
  // },
  {
    subTopic: SubTopics.developing,
    question: `Are there templates for model training on GeneXis?`,
    answer: `GeneXis has a community repository of datasets and libraries, which include model training tools, that Project users can provision to their Projects`,
  },
  {
    subTopic: SubTopics.collaboration,
    question: `Do I have to add other users to my Project for them to access my Project resources?`,
    answer: `No. You can publish your Project’s resources to the community repository on GeneXis Platform for other users to provision to their own projects.`,
  },
  {
    subTopic: SubTopics.collaboration,
    question: `What are the resources available on GeneXis Platform?`,
    answer: (
      <>
        The current list of resources and templates available on GeneXis Platform can be found{" "}
        <FAQLink
          linkPath={PATHS.Resources.path}
          linkText="here"
          customHandler={() => {
            let attemptCount = 0;
            const scrollToElement = () => {
              const element = document.getElementById("Current-Resource-Template");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              } else if (attemptCount < 4) {
                attemptCount++;
                setTimeout(scrollToElement, 400 * attemptCount); // first try at 400, 1200, 2400ms
              }
            };
            scrollToElement();
          }}
        />
        .
      </>
    ),
  },
  {
    subTopic: SubTopics.collaboration,
    question: `What data classification can GeneXis currently support?`,
    answer: `GeneXis supports data classifications of up to Restricted (based on MHA policy).`,
  },
  {
    subTopic: SubTopics.collaboration,
    question: `How can I use my own data for model training?`,
    answer: `Users can provision a dataset resource from GeneXis Platform to their Project, or create a dataset resource within their Project and upload their datasets to the respective resource.`,
  },
  {
    subTopic: SubTopics.demonstrate,
    question: `Can I invite users outside of HTX to view my projects directly on the platform?`,
    answer: (
      <>
        Yes. Invited users will require both{" "}
        <FAQLink linkPath={externalLinks.GeneXisOnboardingForm} linkText="Genexis" /> and{" "}
        <FAQLink linkPath={externalLinks.TechPassPortal} linkText="Techpass" /> accounts to view
        Genexis projects via GSIB or internet-enabled laptops.
      </>
    ),
  },
  // {
  //   subTopic: SubTopics.sharing,
  //   question: `What are the data classification requirements for GeneXis?`,
  //   answer: `GeneXis supports data classifications of up to Restricted (based on MHA policy).`,
  // },
  // {
  //   subTopic: SubTopics.sharing,
  //   question: `How can I use my own data for model training?`,
  //   answer: `GeneXis users can provision a dataset resource from Genexis Platform to their Project and upload their data files to the selected resource.`,
  // },
  {
    subTopic: SubTopics.costing,
    question: `How does GeneXis costing work?`,
    answer: `GeneXis allows you to provision resources for your projects easily, without requiring long-term contracts or multiple layers of approvals. All expenses incurred for your own individual projects and any services consumed on GeneXis will be covered by the Sense-Making & Surveillance Centre of Expertise (S&S CoE) for the time being and made free for all users. Nevertheless, users are advised to exercise prudence and terminate resources when not in use.`,
  },
];

// Interface for a single FAQ entry excluding the subTopic
export interface QAEntry extends Omit<QuestionAnswer, "subTopic"> {}

// Interface for a formatted FAQ section
export interface FormattedFAQs {
  subTopic: SubTopics;
  qaList: QAEntry[];
}

type FAQAccumulator = {
  [key in SubTopics]: FormattedFAQs;
};

// Function to format and organize FAQs by subTopic
const formatFAQsBySubTopic = (): FormattedFAQs[] => {
  const formattedFAQs: FAQAccumulator = faqsQuestions.reduce(
    (accumulator: FAQAccumulator, current: QuestionAnswer) => {
      const { subTopic, question, answer } = current;
      if (!accumulator[subTopic]) {
        accumulator[subTopic] = { subTopic, qaList: [] };
      }
      accumulator[subTopic].qaList.push({ question, answer });
      return accumulator;
    },
    {} as FAQAccumulator
  );
  return Object.values(formattedFAQs);
};

// Formatted and organized FAQs
export const formattedFAQList: FormattedFAQs[] = formatFAQsBySubTopic();
