import React, { ReactNode } from "react";
import {
  Grid,
  Typography,
  Link,
  styled,
  Stack,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
// import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { externalLinks } from "../common/externalLinks";
import { SiteContentContainer } from "../common/styledComponents";
import { PATHS, NavigationRoute } from "../common/paths";
import { useNavigate } from "react-router-dom";
import { ASSET_COLLECTION } from "../common/assets";

const openNewTabProps = {
  target: "_blank",
  rel: "noopener noreferrer",
};

const pages: NavigationRoute[] = Object.values(PATHS);

const FooterCompGrid = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.lighter,
  height: "275px",
  display: "flex",
  alignItems: "center",
}));

// const EMailIcon = styled(MailOutlineOutlinedIcon)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   fontSize: "1.7rem",
//   "&:hover": {
//     color: theme.palette.primary.main,
//     cursor: "pointer",
//   },
// }));

const FooterLinkTypography = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  width: "1px",
  height: "60%",
  alignSelf: "center",
}));

const FooterLinkComp: React.FC<{
  page: NavigationRoute;
  onClick: (arg0: string) => void;
}> = ({ page, onClick }) => (
  // <FooterLink  onClick={() => onClick(page.path)}>
  <FooterLinkTypography
    variant="footerText"
    color={"#000"}
    onClick={() => onClick(page.path)}
  >
    {page.label}
  </FooterLinkTypography>
  // </FooterLink>
);

// const GenexisQuestions: React.FC<{}> = () => (
//   <Grid
//     container
//     direction="column"
//     gap={4}
//     sx={{ height: "226px", justifyContent: "center" }}
//   >
//     <Grid item>
//       <Typography  variant="h1" color="textSecondary">
//         Have questions about GeneXis?
//       </Typography>
//     </Grid>
//     <Grid item>
//       <Grid container alignItems="center" gap={1}>
//         <EMailIcon
//           onClick={() =>
//             (window.location.href = `mailto:${externalLinks.GeneXisSupportEmail}`)
//           }
//         />
//         <Typography variant="h4" color="textSecondary">
//           Contact us at{" "}
//           <Link
//             href={`mailto:${externalLinks.GeneXisSupportEmail}`}
//             color="text.secondary"
//           >
//             {externalLinks.GeneXisSupportEmail}
//           </Link>
//         </Typography>
//       </Grid>
//     </Grid>
//   </Grid>
// );

const SiteNavFooter: React.FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <Grid container item flexDirection={"column"} gap={"30px"}>
      <Grid
        container
        item
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack direction={"row"} gap={"60px"}>
          {pages.map((page, index) => (
            <FooterLinkComp key={index} page={page} onClick={navigate} />
          ))}
        </Stack>
        <Stack direction={"column"} alignItems={"flex-end"}>
          <Typography variant="footerText" color={"#1D1D1D"}>
            We're here for you
          </Typography>
          <Typography variant="bodySmall" color={"#1D1D1D"}>
            Contact{" "}
            <Link
              href={`mailto:${externalLinks.GeneXisSupportEmail}`}
              color="#1D1D1D"
              sx={{ textDecoration: "none" }}
            >
              {externalLinks.GeneXisSupportEmail}
            </Link>
          </Typography>
        </Stack>
      </Grid>
      <Box sx={{ borderBottom: "1px #DDDDDD solid" }} />
      <Grid container item justifyContent={"space-between"}>
        <SiteReportNavComp />
        <SiteInformation />
      </Grid>
    </Grid>
  );
};

const SiteReportNavComp: React.FC<{}> = () => {
  return (
    <Stack
      direction={"row"}
      gap={2}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Stack
        direction={"row"}
        gap={0.5}
        alignContent={"center"}
        alignItems={"center"}
      >
        <FooterLinkTypography
          variant="bodySmall"
          color={"#000"}
          href={externalLinks.ReportVulnerability}
          {...openNewTabProps}
        >
          Report Vulnerabilty
        </FooterLinkTypography>
        <IconButton
          href={externalLinks.ReportVulnerability}
          {...openNewTabProps}
        >
          <img
            src={ASSET_COLLECTION.GeneXisReportVulnerability.filePath}
            alt="Report Vulnerability"
          />
        </IconButton>
      </Stack>
      <VerticalDivider orientation="vertical" flexItem />
      <FooterLinkTypography
        href={externalLinks.PrivacyStatement}
        {...openNewTabProps}
        variant="bodySmall"
        color={"#000"}
      >
        Privacy Statement
      </FooterLinkTypography>
      {/* <VerticalDivider orientation="vertical" flexItem /> */}
      {/* <FooterLink>
        <FooterLinkTypography variant="bodySmall" color="textSecondary">
          Sitemap
        </FooterLinkTypography>
      </FooterLink> */}
    </Stack>
  );
};

const SiteInformation: React.FC<{}> = () => (
  <Stack direction={"column"} alignItems={"flex-end"}>
    <Typography variant="bodySmall" color="#1D1D1D">
      <Typography variant="bodySmall">©</Typography>
      2024 Home Team Science and Technology Agency
    </Typography>
    <Typography variant="bodySmall" color="#1D1D1D">
      Last updated 03 Jun 2024
    </Typography>
  </Stack>
);

interface ComponentWrapper {
  children: ReactNode;
}

const SiteNavFooterCompWrapper: React.FC<ComponentWrapper> = ({ children }) => (
  <SiteContentContainer>{children}</SiteContentContainer>
);

// const GenexisQuestionsWrapper: React.FC<ComponentWrapper> = ({ children }) => (
//   <Grid container item sx={{ bgcolor: "#2C2C2C", height: "222px" }}>
//     <SiteContentContainer>{children}</SiteContentContainer>
//   </Grid>
// );

/*
  Entry Point For Nav Footer
*/

const SiteFooter: React.FC = () => (
  <FooterCompGrid container>
    {/* <GenexisQuestionsWrapper>
      <GenexisQuestions />
    </GenexisQuestionsWrapper> */}
    <SiteNavFooterCompWrapper>
      <SiteNavFooter />
    </SiteNavFooterCompWrapper>
  </FooterCompGrid>
);

export default SiteFooter;
