import React, { Children } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { Step, OnBoardingSteps } from "../dataSource/onBoardingSteps";

// const StepDashedConnector = styled(Box)(({ theme }) => {
//   const borderColor = theme.palette.primary.main;
//   return {
//     borderRight: "3px solid",
//     borderImage: `linear-gradient(to bottom, ${borderColor} 0%, ${borderColor} 10%, transparent 10%, transparent 20%, ${borderColor} 20%, ${borderColor} 30%, transparent 30%, transparent 40%, ${borderColor} 40%, ${borderColor} 50%, transparent 50%, transparent 60%, ${borderColor} 60%, ${borderColor} 70%, transparent 70%, transparent 80%, ${borderColor} 80%, ${borderColor} 90%, transparent 90%, ${borderColor} 100%)`,
//     borderImageSlice: 5,
//     borderImageRepeat: "stretch",
//     padding: `${boxDimensions.height / 1.5}px 0px`,
//   };
// });

const OnboardStepBox = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  backgroundColor: theme.palette.primary.light,
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // boxShadow: theme.shadows[10],
}));

const AllSteps = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 50,
}));

const StepGroup = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 50,
}));

const StepCounter = styled(Typography)(({ theme }) => ({
  ...theme.typography.bodyBold,
  color: theme.palette.primary.main,
  fontSize: "1.375rem",
}));

const StepDetails = styled(Grid)(({ theme }) => ({
  width: 762,
}));

const OnBoardingGuideComponent = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  width: 862,
  marginLeft: "auto",
  marginRight: "auto",
}));

// ---- Components ----
const OnboardStep: React.FC<Step> = ({ title, description }) => (
  <Grid container item direction="column" gap={1}>
    {title}
    {description}
  </Grid>
);

interface OnboardingStepperProps {
  children: React.ReactElement[] | React.ReactElement;
}

const StaticOnboardingStepper: React.FC<OnboardingStepperProps> = ({
  children,
}) => (
  <AllSteps container>
    {Children.map(children, (eachStep: React.ReactElement, index: number) => (
      <StepGroup container item>
        <OnboardStepBox>
          <StepCounter>{index + 1}</StepCounter>
        </OnboardStepBox>
        <StepDetails container item>
          {eachStep}
        </StepDetails>
      </StepGroup>
    ))}
  </AllSteps>
);

// entry point
// ---- Main Component ----
const OnBoardingGuide: React.FC = () => (
  <OnBoardingGuideComponent>
    <Typography variant="h1">Getting Started</Typography>
    <StaticOnboardingStepper>
      {OnBoardingSteps.map((step: Step, index: number) => (
        <OnboardStep {...step} key={index} />
      ))}
    </StaticOnboardingStepper>
  </OnBoardingGuideComponent>
);

export default OnBoardingGuide;
