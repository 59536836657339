import { styled, Box } from "@mui/material";

export const SiteLayoutContainer = styled(Box)(({ theme }) => ({
  width: "1440px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflowX: "clip",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.05)",
}));

export const SiteContentContainer = styled(Box)(({ theme }) => ({
  width: "1224px",
  margin: "0 auto",
}));
