import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Box,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type SelectedOptionsType = string[];

const SelectDropDownIcon: React.FC<{ props: any }> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00002 9.05735L10.3907 6.66668C10.6507 6.40668 11.0727 6.40668 11.3334 6.66668C11.594 6.92735 11.594 7.34935 11.3334 7.60935L8.94269 10C8.42202 10.5207 7.57803 10.5207 7.05736 10L4.66669 7.60935C4.40602 7.34935 4.40602 6.92735 4.66669 6.66668C4.92736 6.40668 5.34936 6.40668 5.60936 6.66668L8.00002 9.05735Z"
      fill="#7364E1"
    />
  </svg>
);

const MenuProps = {
  PaperProps: {
    sx: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

      width: "344px",
      marginLeft: "120px",
      marginTop: "12px",
      padding: "4px",
      borderRadius: "8px",
      // boxShadow: "0px 1px 6px 0px #0000001F",
        boxShadow: '0px 1px 6px 0px #0000001F, 0px 0px 1px 0px #0000001F',
      "& .MuiList-root .Mui-focusVisible": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "primary.light",
        },
      },

    },
  },
};

const FilterTypo = {
  fontSize: "0.75rem",
  fontWeight: 500,
  lineHeight: "1.5rem",
};

const MultipleSelectCheckmarks: React.FC<{
  title: string;
  content: string[];
  onChange: (types: string[]) => void;
}> = ({ title, content, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsType>(
    []
  );
  const [isSelected, setIsSelected] = useState(false);

  const handleChange = (event: SelectChangeEvent<SelectedOptionsType>) => {
    const value = event.target.value as SelectedOptionsType;
    setSelectedOptions(value);
    onChange(value);
    setIsSelected(value.length > 0);
  };

  const renderValue = (selected: SelectedOptionsType) => {
    if (selected.length === 0)
      return (
        <Typography sx={FilterTypo} component={"div"}>
          {title}
        </Typography>
      );

    return (
      <Box
        sx={{
          maxWidth: 141,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {selected.map((value) => (
          <>
            <Typography variant="bodySmall">{value}</Typography>
            {selected.indexOf(value) === selected.length - 1 ? null : (
              <Typography variant="bodySmall">, </Typography>
            )}
          </>
        ))}
      </Box>
    );
  };

  return (
    <FormControl sx={{ m: 1, bgcolor: "transparent" }}>
      <Select
        id="mutiple-select"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={renderValue}
        placeholder={title}
        displayEmpty
        MenuProps={MenuProps}
        IconComponent={SelectDropDownIcon}
                
        sx={(theme) => ({
          borderRadius: "20px",
          width: "fit-content",
          height: "24px",
          color: isSelected ? "#FFF" : theme.palette.primary.main,
          bgcolor: isSelected ? theme.palette.primary.main : "#FFF",
          "& .MuiSvgIcon-root": {
            color: isSelected ? "#FFF" : theme.palette.primary.main,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            backgroundColor: isSelected
              ? theme.palette.primary.dark
              : theme.palette.primary.light,
          },
        })}
      >
        {content.map((name) => (
          <MenuItem
            key={name}
            value={name}
            sx={{
              "&:hover": {
                backgroundColor: "primary.light",
                color: "primary.main",
              },
              padding: "10px 12px",
              borderRadius: "8px",
              "& span": {
                padding: "0px 8px 0px 0px",
              },
            }}
          >
            <Checkbox
              disableRipple
              icon={
                <CheckBoxOutlineBlankIcon
                  sx={{
                    border: "1px solid #CCCCCC",
                    borderRadius: "4px",
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#FFFFFF",
                  }}
                />
              }
              checked={selectedOptions.indexOf(name) > -1}
              checkedIcon={
                <CheckBoxIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "primary.main",
                  }}
                />
              }
            />
            <ListItemText
              sx={{
                height: "20px",
              }}
              primary={
                <Typography variant="bodySmall" sx={{ lineHeight: "20px" }} component={'div'}>
                  {name}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectCheckmarks;
